import React from "react"
import "../styles/index.css"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import PostLink from "../components/post-link"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  return <div>
      <Helmet>
      <meta charSet="utf-8" name="robots" content="noindex" name="theme-color" content="#000" />
      <title>ARCHIVE 〜 🌴 gs / xyz</title>
      <link rel="canonical" href="https://gagansingh.xyz" />
      <script src="https://kit.fontawesome.com/6b889176de.js" crossorigin="anonymous"></script>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    </Helmet>
    <a href="/" className="main-header">🌴🌴🌴</a>
    <br /><br /><br />
    <h3>ARCHIVE</h3>
    {Posts}
    <br /><br /><br /><br />
    <a href="javascript:history.back()" className="back"> {"<"} go back </a>
    <br /><br /><br /><br /><br />
    <div className="top-bar"><a href="/">☺</a></div>
  </div>


}
export default IndexPage
export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    }
  }
`